/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Facts = {

    tilesloaded: false,

    loadTiles: function() {
      var tiles = $('.js-shuffle-item');

      $.each(tiles, function(index, value) {
        (function(index, value) {
          var wait = (index * 150) + 300;
          setTimeout(function() {
            $(value).removeClass('hide-line').find('.js-target').removeClass('scale0');
          }, wait);
        })(index, value);
        tilesloaded = true;
      });
    },

    getCookie: function() {
      var name = "turism_facts_visit=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');

      for ( var i = 0; i <ca.length; i++ ) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    // All pages
    'common': {
      shuffleInstance: '',

      applyShuffle: function() {
        var Shuffle = window.Shuffle,
            element = document.querySelector('.js-shuffle'),
            sizer = element.querySelector('.js-shuffle-item');

        this.shuffleInstance = new Shuffle(element, {
          itemSelector: '*',
          sizer: sizer,
          gutterWidth: '20px'
        });
      },

      detectSelectedPostType: function() {
        var newClass = window.location.pathname.split('/');

        if (newClass.length > 1) {
          if (newClass[1] === 'numbers' || newClass[1] === 'testimonials' || newClass[1] === 'news') {
            $('.js-nav-container').addClass(newClass[1] + '-posts');
          }
        } else {
          if (newClass[1] === 'numbers' || newClass[1] === 'testimonials' || newClass[1] === 'news') {
            $('.js-nav-' + newClass[1]).addClass('selected');
          } else {
            $('body').removeClass('single-numbers single-testimonials');
          }
        }
      },

      removePostTypeNavClass: function() {
        lastClass = $('.js-nav-container').attr('class').split(' ').pop();
        if (lastClass !== 'pt-xxl-l' ) {
          $('.js-nav-container').removeClass(lastClass);
        }
      },

      fbShare: function(url) {
        FB.ui({
          method: 'share',
          mobile_iframe: true,
          href: url,
        });
      },

      share: function(e) {
        e.preventDefault();
        var url = $(this).data('url');
        Facts.common.fbShare(url);
      },

      initFb: function() {
        window.fbAsyncInit = function() {
          FB.init({
            appId      : '840540622778069',
            xfbml      : true,
            version    : 'v2.10'
          });
          FB.AppEvents.logPageView();
        };

        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.src = "//connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
      },

      init: function() {
        var that = this;
        this.applyShuffle();
        this.removePostTypeNavClass();
        this.detectSelectedPostType();
        this.initFb();
        $('.js-fb').on('click', this.share);

        if ($('#barba-wrapper').length) {
          var control;
          Barba.Pjax.start();

          Barba.Dispatcher.on('newPageReady', function(e) {
            that.shuffleInstance.destroy();
          });

          Barba.Dispatcher.on('transitionCompleted', function() {
            that.removePostTypeNavClass();
            that.detectSelectedPostType();
            that.applyShuffle();
            Facts.loadTiles();
            $('.js-fb').on('click', that.share);
          });

          if (Facts.getCookie() === 'first') {
            Facts.loadTiles();
          }
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    'home': {
      setCookie: function() {
        var d = new Date();
        d.setTime(d.getTime() + (2*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = 'turism_facts_visit=first;' + expires + 'path=/';
      },

      playVideo: function() {
        $(this).scrollTop(0);
        $('body').css({'overflow': 'hidden'});
        $('video')[0].play();
        $('#video').removeClass('dn');
      },

      closeVideo: function() {
        $('#video').on( 'timeupdate', function(event) {
          if ( this.currentTime >= this.duration - 1 ) {
            $('#video').fadeOut(300);
            Facts.loadTiles();
            $('body').css({'overflow': 'initial'});
          }
        });
      },

      checkFirstVisit: function() {
        var firstVisit = Facts.getCookie();
        if ( firstVisit === 'first' ) {
          $('#video').hide();
        } else {
          this.playVideo();
          this.closeVideo();
          this.setCookie();
        }
      },

      init: function() {
        if( $(window).outerWidth() > 480 ) {
          this.checkFirstVisit();
        } else {
          Facts.loadTiles();
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    'single': {
      init: function() {
        Facts.loadTiles();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Facts;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
